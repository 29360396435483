<template>
    <div class="">
        <div class="flex justify-space-between">
            <div class="box self-flex-end"><h2 class="mv-0">BOOKS</h2></div>
            <div class="box"><el-button @click="$onCommandParams('home')">BACK</el-button></div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <div class="flex gaps mt-10">
          <div class="box flex grow" v-for="(type, index) in types" :key="index + '-type'">
            <div class="box grow card-base"
              :class="[currentBusketItem.typeId === index ? 'bg-accent' : 'locked-panel']">
              <h4 class="box grow pl-5 m-10">{{type.name}}</h4>
            </div>
          </div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <div class="flex wrap grid-12">
            <div v-for="(s, index) in todaySessions" :key="index + '-session'"
              :class="[currentBusketItem.sessionIdx === index ? 'bg-accent' : 'locked-panel']"
              class="box card-base m-5 p-5 session-time">
                <div class="flex column">
                  <h5 class="box center p-5" :class="[currentBusketItem.sessionIdx === index ? '' : 'o-050']">{{s.name}}</h5>
                  <h4 class="box center">{{s.time}}</h4>
                </div>
            </div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <div class="flex column gaps">
          <app-customer-select
            @selectCustomer="handleChangePerson"
            :basket="basket"
            :basketId="currentBusketItem.basketId"
            :clientIds="currentBusketItem.clientIds"
            :basketWaiwers="basketWaiwers"></app-customer-select>

            <div class="box flex gaps">
              <div class="box left center" style="min-width: 100px;">
                <h4 class="mt-10 mb-5 o-050">NUMBER</h4>
              </div>
              <div class="box">
                <el-input-number
                  :controls="false"
                  size="small"
                  disabled
                  :value="currentBusketItem.times"></el-input-number>
              </div>
            </div>

            <div class="box grow flex gaps">
              <div class="box left center" style="min-width: 100px;">
                <h4 class="mt-10 mb-5 o-050">CUSTOMER</h4>
              </div>
              <div class="box align-vertical clickable info-text"
                @click="onClickWaiver(currentBusketItem.customerId)">
                {{currentBusketItem.customerName}}
                <!-- <el-input
                  size="small"
                  disabled
                  :value="currentBusketItem.customerName"></el-input> -->
              </div>
            </div>

            <el-divider class="mv-10 o-020"></el-divider>

            <div class="box grow flex gaps">
              <div class="box left center" style="width: 100px;">
                <h4 class="mt-10 mb-5 o-050">PREPAID ADDONS</h4>
              </div>
              <div class="box flex column gaps">
                <div class="box flex gaps"
                  v-for="(a, index) in currentBusketItem.addons" :key="index + '-addon'">
                  <el-input
                    class="box"
                    size="small"
                    disabled
                    :value="a.name"></el-input>
                  <el-input-number
                    :controls="false"
                    class="box"
                    size="small"
                    disabled
                    :value="a.count"></el-input-number>
                </div>
              </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import AppCustomerSelect from './components/TicketCustomerSelect'

export default {
  name: 'SaleBooks',

  components: {
    AppCustomerSelect
  },

  props: {
    members: {
      type: Array,
      default: function () {
        return []
      }
    },
    basket: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data () {
    return {
      value1: [],
      value2: [],
      clientsIds: []
    }
  },
  computed: {
    ...mapState({
      types: state => state.type.types
    }),
    ...mapGetters([
      'todaySessions',
      'basketWaiwers',
      'currentSessionIndex',
      'getBasketRowById'
    ]),
    currentBusketItem () {
      return this.getBasketRowById[this.$route.params.id] ||
      {
        typeId: '',
        typeIdx: 0,
        sessionIdx: this.currentSessionIndex,
        clientIds: []
      }
    },
    value: {
      get () {
        return this.currentBusketItem.clientIds.map((x) => x)
      },
      set (newItem) {
        return newItem
      }
    }
  },
  methods: {
    ...mapActions(['editBasketItem', 'addWaiwerToBasket']),
    onClickWaiver (customerId) {
      this.addWaiwerToBasket({ code: customerId, isRemove: false })
    },
    handleChangePerson (clientIds) {
      let clientNames = clientIds.map(v => {
        let c = this.basketWaiwers.find(w => w.value === v)
        return c ? c.label : ''
      })
      this.editBasketItem({
        basketId: this.currentBusketItem.basketId,
        content: {
          clientIds,
          clientNames
        }
      })
    },
    isDisabled (id) {
      return this.basket
        .filter(b => ['Tickets', 'Books'].includes(b.category) &&
          b.basketId !== this.currentBusketItem.basketId)
        .some(b => b.clientIds.includes(id))
    }
  }
}
</script>
